import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs/tabs.page';
const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'uebernahme',
        children: [
          {
            path: '',
            loadChildren: './pages/uebernahme/uebernahme.module#UebernahmePageModule'
          }
        ]
      },
      {
        path: 'archiv',
        children: [
          {
            path: '',
            loadChildren: './pages/archiv/archiv.module#ArchivPageModule'
          }
        ]
      },
      {
        path: '',
        redirectTo: '/tabs/uebernahme',
        pathMatch: 'full'
      }
    ]
  },
  { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'change-password', loadChildren: './pages/change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'uebernahme', loadChildren: './pages/uebernahme/uebernahme.module#UebernahmePageModule' },
  { path: 'archiv', loadChildren: './pages/archiv/archiv.module#ArchivPageModule' },
  { path: 'benutzer', loadChildren: './pages/benutzer/benutzer.module#BenutzerPageModule' },
  { path: 'bestaetigung', loadChildren: './pages/bestaetigung/bestaetigung.module#BestaetigungPageModule' },
  { path: 'info', loadChildren: './pages/info/info.module#InfoPageModule' },
  { path: 'objekte', loadChildren: './pages/objekte/objekte.module#ObjektePageModule' },
  { path: 'view-pdf', loadChildren: './pages/view-pdf/view-pdf.module#ViewPdfPageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabsPage } from './tabs/tabs.page';

import { AuthService, AuthServiceProvider } from './providers/auth.service';
import { DynamoDB } from './providers/aws.dynamodb';
import { PartnerStorage } from './providers/partner.storage';
import { ContractStorage } from './providers/contract.storage';
import { ListContracts } from './providers/list.contracts';
import { DemoData } from './demo/demo.data';
import { LambdaHelper } from './providers/aws.lambda';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [AppComponent, TabsPage],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService, AuthServiceProvider,
    DynamoDB,
    PartnerStorage,
    ContractStorage,
    ListContracts,
    DemoData,
    LambdaHelper,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { Injectable } from '@angular/core'
import { Config as AppConfig } from '@ionic/angular'
// import { LoginPage } from '../pages/login/login.page';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, ICognitoUserPoolData, CognitoUserSession } from 'amazon-cognito-identity-js'
import { environment } from '../../environments/environment';
import { Observable, Subject, from } from 'rxjs'
var jwtDecode = require('jwt-decode');
declare var AWS: any;
// AWS.config.correctClockSkew = true

// declare const aws_cognito_region;
// declare const aws_user_pools_id;
// declare const aws_user_pools_web_client_id;
// declare const aws_cognito_identity_pool_id;

interface SigninObj {
  code: string;
  cognitoUser: CognitoUser;
}

@Injectable()
export class AuthService {
  private unauthCreds: any
  private poolData: ICognitoUserPoolData
  private userPool: CognitoUserPool
  private _cognitoUser: CognitoUser
  private session: CognitoUserSession
  private _signoutSubject: Subject<string> = new Subject<string>()
  private _signinSubject: Subject<string> = new Subject<string>()
  public email: string
  public accessKeyId: String
  public secretAccessKey: String
  public sessionToken: String
  public region: String
  public partnerID: any;
  public partnerName: String ="";
  public UserID: String="";
  public timeoutID : any;
  public signedUrl: string;

  constructor() {
    AWS.config.region = environment.aws_cognito_region;
    this.poolData = { UserPoolId: environment.aws_user_pools_id, ClientId: environment.aws_user_pools_web_client_id}
    console.log(this.poolData);
    this.userPool = new CognitoUserPool(this.poolData)
    this.refreshOrResetCreds()
  }

  get signoutNotification() { return Observable.create(fn => this._signoutSubject.subscribe(fn)) }
  get signinNotification() { return Observable.create(fn => this._signinSubject.subscribe(fn)) }
  get cognitoUser(): CognitoUser { return this._cognitoUser }
  get currentIdentity(): string { return AWS.config.credentials.identityId }
  isUserSignedIn(): boolean { return this._cognitoUser !== null }

  private refreshOrResetCreds() {
    this._cognitoUser = this.userPool.getCurrentUser()

    if (this._cognitoUser !== null) {
      this.refreshSession()
    } else {
      this.resetCreds()
    }
  }

  private setCredentials(newCreds) {
    AWS.config.credentials = newCreds
  }

  private buildLogins(token) {
    let key = `cognito-idp.${environment.aws_cognito_region}.amazonaws.com` + '/' + environment.aws_user_pools_id;
    let json = { IdentityPoolId: environment.aws_cognito_identity_pool_id, Logins: {} }
    json.Logins[key] = token
    return json
  }

  private buildCreds() {
    let json = this.buildLogins(this.session.getIdToken().getJwtToken())
    return new AWS.CognitoIdentityCredentials(json)
  }

  public checkout_expired(app) {
    try{
      if(AWS.config.credentials.expireTime){
        if (AWS.config.credentials.needsRefresh()===true){
          this.signout()
          // app.getRootNav().push(LoginPage);
        }
      }
    }catch(e){
      console.log(e)
    }
  }

  private saveCreds(session, cognitoUser?): void {
    
    this.session = session
    let sessionIdInfo = jwtDecode(session.getIdToken()['jwtToken'])
    this.UserID = sessionIdInfo["cognito:username"]
    this.setPartnerID(sessionIdInfo['cognito:groups'])
    if (cognitoUser) { this._cognitoUser = cognitoUser }
    this.setCredentials(this.buildCreds())
  }

  private getNewCognitoUser(creds): CognitoUser {
    return new CognitoUser({ Username: creds.username, Pool: this.userPool })
  }

  private authDetails(creds): AuthenticationDetails {
    return new AuthenticationDetails({ Username: creds.username, Password: creds.password })
  }

  private refreshSession(): Promise<CognitoUserSession> {
    let self = this
    return new Promise((resolve, reject) => {
      self._cognitoUser.getSession((err, session) => {
        if (err) { console.log('Error refreshing user session', err); return reject(err) }
        console.log(`${new Date()} - Refreshed session for ${self._cognitoUser.getUsername()}. Valid?: `, session.isValid())
        self.saveCreds(session)
        resolve(session)
      })
    })
  }

  private resetCreds(clearCache: boolean = false) {
    console.log('Resetting credentials for unauth access')
    AWS.config.region = environment.aws_cognito_region;
    this._cognitoUser = null
    this.unauthCreds = this.unauthCreds || new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.aws_cognito_identity_pool_id });
    this.partnerID = "";
    this.UserID = "";
    if (clearCache) { this.unauthCreds.clearCachedId() }
    this.setCredentials(this.unauthCreds)
  }

  private buildAttributes(creds): Array<CognitoUserAttribute> {
    let attributeList = []
    let attributeEmail = new CognitoUserAttribute({ Name: 'email', Value: creds.email })
    let attributeName = new CognitoUserAttribute({ Name: 'preferred_username', Value: creds.username })
    attributeList.push(attributeEmail)
    attributeList.push(attributeName)
    return attributeList
  }

  private _getCreds(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        AWS.config.credentials.get((err) => {
          if (err) { return reject(err) }
          resolve(AWS.config.credentials)
        })
      } catch (e) { reject(e) }
    })
  }

  getCredentials(): Observable<any> {
    let result = null
    if (this._cognitoUser === null) { result = this._getCreds() }
    else if (this.session && this.session.isValid()) { result = this._getCreds() }
    else { result = this.refreshSession().then(this._getCreds) }
    return from(result)
  }

  signout() {
    if (this._cognitoUser) {
      let name = this._cognitoUser.getUsername()
      this._cognitoUser['signOut']()
      this.resetCreds(true)
      this._signoutSubject.next(name)

    }
  }

  register(creds): Promise<CognitoUser> {
    let self = this
    return new Promise((resolve, reject) => {
      try {
        self.userPool.signUp(creds.username, creds.password, self.buildAttributes(creds), null, (err, result) => {
          if (err) { return reject(err) }
          console.log('Register', result)
          resolve(result.user)
        })
      } catch (e) { reject(e) }
    })
  }

  confirm(creds): Promise<CognitoUser> {
    let cognitoUser = this.getNewCognitoUser(creds)
    return new Promise((resolve, reject) => {
      try {
        console.log('Confirming...', CognitoUser)
        cognitoUser.confirmRegistration(creds.confcode, true, (err, result) => {
          if (err) { return reject(err) }
          resolve(result.CognitoUser)
        })
      } catch (e) { reject(e) }
    })
  }

  signin(creds, navCtrl): Promise<SigninObj> {
    let cognitoUser = this.getNewCognitoUser(creds)
    let self = this

    return new Promise((resolve, reject) => {
      try {
        cognitoUser.authenticateUser(self.authDetails(creds), {
          onSuccess: (session) => {
            console.log(`Signed in user ${cognitoUser.getUsername()}. Sessiong valid?: `, session.isValid())
            self.saveCreds(session, cognitoUser)
            self._signinSubject.next(cognitoUser.getUsername())
            self.email = creds.username
            self.region = AWS.config.region
            resolve({ code: "onSuccess", cognitoUser: cognitoUser });
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            console.log(userAttributes)
            delete userAttributes.email_verified;
            self.email = creds.username
            resolve({ code: "newPasswordRequired", cognitoUser: cognitoUser });
          },
          mfaRequired: (challengeName, challengeParameters) => { },
          customChallenge: (challengeParameters) => { },
          onFailure: reject
        })
      } catch (e) {
        console.log("error login");
        console.log(e);
        reject(e) }
    })
  }

  setPartnerID(groups){
    let str = "partner_"
    for(let group of groups){
      if(group.search(str) != -1){
        this.partnerID = group.replace(str,"")
       }
    }
  }

  changePassword(newpassword, cognitoUser): Promise<CognitoUser> {
    let self = this
    return new Promise((resolve, reject) => {
      try {
        cognitoUser.completeNewPasswordChallenge(newpassword, [], {
          onSuccess: function (result) {
            self.saveCreds(result, cognitoUser)
            self._signinSubject.next(cognitoUser.getUsername())
            resolve(cognitoUser)
          },
          onFailure: function (error) {
            reject(error)
          }
        })
      } catch (e) { reject(e) }
    })
  }
}

export const authServiceFactory = (config: AppConfig) => { return new AuthService() }

export let AuthServiceProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: [AppConfig]
}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Config as AppConfig } from '@ionic/angular';
import { environment } from '../../environments/environment';
var AWS = require('aws-sdk');

// declare const aws_lindeleasing_sns_lambda_topic;
// declare const aws_lindeleasing_lambda_confirmation_pdf;
// declare const aws_lindeleasing_lambda_signed_url;

@Injectable()
export class LambdaHelper{
  static _lambda:any;
  static _sns:any;

  constructor(public auth: AuthService,
              public config: AppConfig) { }

  getLambda() : Promise<any>{
    return new Promise((resolve, reject) => {
      if (LambdaHelper._lambda === undefined) {
          this.auth.getCredentials().subscribe((newcredentials) => {
            LambdaHelper._lambda = new AWS.Lambda({
              params: {
                credentials: newcredentials
              }
            });
            resolve(LambdaHelper._lambda);
          })
        } else {
          resolve(LambdaHelper._lambda);
        }
    })
  }

  getSns() : Promise<any>{
    return new Promise((resolve, reject) => {
      if (LambdaHelper._sns === undefined) {
          this.auth.getCredentials().subscribe((newcredentials) => {
            LambdaHelper._sns = new AWS.SNS({
              params: {
                credentials: newcredentials
              }
            });
            resolve(LambdaHelper._sns);
          })
        } else {
          resolve(LambdaHelper._sns);
        }
    })
  }


  sendNotification(data, cb) {
    let params = {
        Message: JSON.stringify({
            default: "",
            lambda: JSON.stringify(data)
        }),
        MessageStructure: "json",
        TopicArn: environment.aws_lindeleasing_sns_lambda_topic
    };

    this.getSns().then((sns) => {
      sns.publish(params, cb);
    }, (err) => {
      console.log(err)
    })
  }

  invokeLambda(data, cb) {
    let params = {
        FunctionName: environment.aws_lindeleasing_lambda_confirmation_pdf,
        LogType: "Tail",
        Payload: JSON.stringify(data)
    };
    this.getLambda().then((lambda) => {
      lambda.invoke(params, cb);
    }, (err) => {
      console.log(err)
    })
  }

  async getSignedUrl() {
    let params = {
        FunctionName: environment.aws_lindeleasing_lambda_signed_url,
        LogType: "Tail",
        Payload: JSON.stringify({ Partner_ID: this.auth.partnerID })
    };
    return new Promise((resolve, reject) => {
      this.getLambda().then((lambda) => {
        lambda.invoke(params, (err, data) => {
          if (err) reject(err);
          else resolve(JSON.parse(data.Payload));
        });
      }, (err) => {
        console.log(err);
      });
    });
  }

  static get parameters() {
    return [[AuthService], [AppConfig]];
  }
}
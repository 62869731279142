import { ContractInfo } from '../providers/lindeleasing.d';

export class DemoData {

    constructor() {}

    static getPartnerID(){
        return '253';
    }

    static getUsername() {
        return "test2@test.com";
    }

    static getPartnerName() {
        return "Neotechnik Fördersysteme";
    }

    static getContracts() {
        return [
            {
                Contract_ID: "6358984",
                Customer_Name1: "Neotechnik Fördersysteme",
                Customer_Name2: "",
                Customer_Name3: "",
                Partner_ID: "243",
                PdfUrl: '//cdn.mozilla.net/pdfjs/helloworld.pdf'
            },
            {
                Contract_ID: "6394098",
                Customer_Name1: "Neotechnik Fördersysteme",
                Customer_Name2: "",
                Customer_Name3: "",
                Partner_ID: "243",
                PdfUrl: '//cdn.mozilla.net/pdfjs/helloworld.pdf'
            },
            {
                Contract_ID: "6368197",
                Customer_Name1: "MEM- Bauchemie GmbH",
                Customer_Name2: "",
                Customer_Name3: "",
                Partner_ID: "243",
                PdfUrl: '//cdn.mozilla.net/pdfjs/helloworld.pdf'
            }
        ];
    }

    static getObjekteByContract() {
        return {
            Contract: {
                ID: "6368197"
            },
            Customer: {
                City: "Leer",
                Name: "MEM- Bauchemie GmbH",
                Name2: null,
                Name3: null,
                OrderDate: "06.09.17",
                OrderNumber: null,
                Street: "26789",
                ZipCode: "26789",
                ID: "234"
            },
            Objects: [
                {
                    ChassisNumber: "312314",
                    CommissionNumber: "pp",
                    ConstructionYear: "2017",
                    CostCenter: null,
                    ID: "2247305",
                    LocationCity: "Leer",
                    LocationName1: "MEM- Bauchemie GmbH",
                    LocationName2: null,
                    LocationName3: null,
                    LocationStreet: "Am Emsdeich 52",
                    LocationZipCode: "26789",
                    Name: "Linde Hochhubwagen L 12/1172",
                    OrderDate: "06.09.17",
                    OrderNumber: null,
                    Type: "Lagertechnikgeräte",
                    Used: "N",
                    Edit: "",
                    Sparte: 66,
                    EditAux: false,
                    ShowLoad: false,
                    CopyChassisNumber: "312314"
                },
                {
                    ChassisNumber: null,
                    CommissionNumber: null,
                    ConstructionYear: "2017",
                    CostCenter: null,
                    ID: "2247306",
                    LocationCity: "Leer",
                    LocationName1: "MEM- Bauchemie GmbH",
                    LocationName2: null,
                    LocationName3: null,
                    LocationStreet: "Am Emsdeich 52",
                    LocationZipCode: "26789",
                    Name: "Linde Hochhubwagen L 12/1172",
                    OrderDate: "06.09.17",
                    OrderNumber: null,
                    Type: "Lagertechnikgeräte",
                    Used: "N",
                    Edit: "",
                    Sparte: 67,
                    EditAux: false,
                    ShowLoad: false,
                    CopyChassisNumber: null
                },
                {
                    ChassisNumber: null,
                    CommissionNumber: null,
                    ConstructionYear: "2017",
                    CostCenter: null,
                    ID: "2247307",
                    LocationCity: "Leer",
                    LocationName1: "MEM- Bauchemie GmbH",
                    LocationName2: null,
                    LocationName3: null,
                    LocationStreet: "Am Emsdeich 52",
                    LocationZipCode: "26789",
                    Name: "Linde Hochhubwagen L 12/1172",
                    OrderDate: "06.09.17",
                    OrderNumber: null,
                    Type: "Lagertechnikgeräte",
                    Used: "N",
                    Edit: "",
                    Sparte: 67,
                    EditAux: false,
                    ShowLoad: false,
                    CopyChassisNumber: null
                }
            ],
            Partner: {
                City: "Leer",
                ID: "243",
                Name: "Neotechnik Fördersysteme",
                Name2: "Göthe & Prior GmbH & Co. KG",
                Name3: null,
                Street: "Industriestraße 57",
                ZipCode: "33689",
                Email: "luis.adasilvaf@gmail.com"
            },
            Takeover: {
              Date: '',
              Signatory: '',
              SignatureDate: '',
              Latitude: '',
              Longitude: '',
              CustomerEmail: '',
              SendTimestamp: '',
              UserID: '',
              SignatoryOpcional: '',
              SignatureData: '',
              SignatureOpcionalData: ''
            }
        }
    }

  static getObjectsByContractAux() {
        return [
            {
                Partner_ID: '243',
                Object_ID: '2247305',
                Contract_ID: '6368197',
                Object_ChassisNumber: 'AA'
            },
            {
                Partner_ID: '243',
                Object_ID: '2247306',
                Contract_ID: '6368197',
                Object_ChassisNumber: 'BB'
            },
        ]
    }

    static getCustomers() {
        return [
            { Customer_Name1: "MEM- Bauchemie GmbH" },
            { Customer_Name1: "Neotechnik Fördersysteme" }
        ]
    }

    static getContractInfo(): ContractInfo {
        return DemoData.getObjekteByContract();
    }
}

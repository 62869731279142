export class ListContracts{

  contractsArray: any;
  customerArray: any;
  contractsSearch: any;
  contract_id: string;
  uniqueContracts: any;
  uniqueCustomers: any;
  customer_name: string;
  contractHelper: any;
  arhive: any;

  constructor(){
    this.contractsArray = []
    this.contractsSearch = []
    this.customerArray = []
    this.contract_id = ""
    this.uniqueContracts = []
    this.uniqueCustomers = []
    this.customer_name = "Empty";
  }

  setContractHelper(contractHelper){
    this.contractHelper = contractHelper;
  }

  // swap(arr, i, j){
  //   var temp = arr[i];
  //   arr[i] = arr[j];
  //   arr[j] = temp;
  // }

  // partition(arr, pivot, left, right){
  //     var pivotValue = arr[pivot],
  //         partitionIndex = left;

  //     for(var i = left; i < right; i++){
  //     if(arr[i] < pivotValue){
  //       this.swap(arr, i, partitionIndex);
  //       partitionIndex++;
  //     }
  //   }
  //   this.swap(arr, right, partitionIndex);
  //   return partitionIndex;
  // }

  // quickSort(arr, left, right){
  //   let pivot;
  //   let partitionIndex;

  //   if(left < right){
  //     pivot = right;
  //     partitionIndex = this.partition(arr, pivot, left, right);

  //     //sort left and right
  //     this.quickSort(arr, left, partitionIndex - 1);
  //     this.quickSort(arr, partitionIndex + 1, right);
  //   }
  //   return arr;
  // }

  getUniqueContracts(data, opts = {}) {
    let uniqueContracts = []
    let contractsArray = []
    for (var i = 0; i < data.length; i++) {
      if(uniqueContracts.indexOf(data[i].Contract_ID) == -1) {
        uniqueContracts.push(data[i].Contract_ID)
        contractsArray.push(data[i])
      }
    }

    let orderedContracts = null
    if (Object.keys(opts).indexOf('sort') > -1) {
      orderedContracts = contractsArray.sort((a, b) => {
        return parseInt(a.Contract_ID) - parseInt(b.Contract_ID);
      });
    }
    this.uniqueContracts = uniqueContracts;
    this.contractsArray = orderedContracts || contractsArray;
    return contractsArray;
  }

  getContractsBeginningWith(table, contract_id):Promise<any> {
    this.contract_id = contract_id
    this.contractHelper.lastEvaluatedKey = null

    return new Promise((resolve , reject) => {
      if (this.contract_id.length >= 3) {
        if (this.customer_name == "Empty") { this.customer_name = null }

        this.contractHelper.getContracts(table, this.customer_name, this.contract_id).then((data)=>{
          //this.customer_name = "Empty";
          resolve(this.getUniqueContracts(data));
        })
      } else if (this.contract_id.length == 0){
        this.contractHelper.getContracts(table, this.customer_name).then((data) => {
          resolve(this.getUniqueContracts(data));
        });
      } else if (this.contract_id.length > 0 && this.contract_id.length < 3){
        this.contractsArray = []
        this.uniqueContracts = []
        resolve(this.contractsArray)
      }
    })
  }

  onInitial(table):Promise<any>{
    return new Promise((resolve , reject) => {
      this.contractHelper.getContracts(table).then((data) => {
        resolve(this.getUniqueContracts(data));
      });
    })
  }

  getCustomerList(table, opts = {}):Promise<any>{
    return new Promise((resolve , reject) => {
      this.contractHelper.getCustomers(table).then((data) => {
        let uniqueCustomers = []
        let customerArray = []
        customerArray.push({'Customer_Name1':'Alle', 'Customer_ID': '0'})

        for (var i = 0; i < data.length; i++) {
          if(uniqueCustomers.indexOf(data[i].Customer_Name1)==-1){
            uniqueCustomers.push(data[i].Customer_Name1)
            customerArray.push(data[i])
          }
        }

        let orderedCustomers = null;
        if (Object.keys(opts).indexOf('sort') > -1) {
          orderedCustomers = customerArray.sort((a, b) => {
            let fullnameA = `${a.Customer_Name1}${a.Customer_Name2}${a.Customer_Name3}`;
            let fullnameB = `${b.Customer_Name1}${b.Customer_Name2}${b.Customer_Name3}`;

            return fullnameA.localeCompare(fullnameB);
          });
        }

        this.uniqueCustomers = uniqueCustomers;
        this.customerArray = orderedCustomers || customerArray;
        resolve(this.customerArray)
      });
    })
  }

  getContractsByCustomer(table,selectedCustomer: any):Promise<any> {
    this.contractHelper.lastEvaluatedKey = null

    return new Promise((resolve, reject) =>{
      this.contractHelper.getContracts(table, selectedCustomer).then((data) => {
        resolve(this.getUniqueContracts(data));
      });
    })
  }

  getDataSet(){
    return this.uniqueContracts;
  }

  getContratsArray(){
    return this.contractsArray
  }

  getCustomerName(){
    return this.customer_name
  }
}
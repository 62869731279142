import { Injectable } from '@angular/core';
import { Config as AppConfig } from '@ionic/angular';
import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { DynamoDB } from './aws.dynamodb';
import {
    LastKey,
    ContractInfo,
    Partner,
    Customer,
    Contract,
    Takeover,
    Confirmation
} from './lindeleasing.d';

// declare const aws_lindeleasing_dynamodb_contracts_index;
// declare const aws_lindeleasing_dynamodb_customer_index;
// declare const aws_table_tmp;

@Injectable()
export class ContractStorage extends DynamoDB {
    public lastEvaluatedKey: LastKey;

    constructor(protected config: AppConfig,
        protected auth: AuthService) {
        super(config, auth);
        this.lastEvaluatedKey = null;
    }

    getObjectsByContractAux(Contract_ID, Partner_ID): Promise<any> {
        let params = {
            TableName: environment.aws_table_tmp,
            IndexName: environment.aws_lindeleasing_dynamodb_contracts_index,
            Select: "SPECIFIC_ATTRIBUTES",
            KeyConditions: {
                'Contract_ID': {
                    ComparisonOperator: 'EQ',
                    AttributeValueList: [Contract_ID]
                },
                'Partner_ID': {
                    ComparisonOperator: 'EQ',
                    AttributeValueList: [Partner_ID]
                }
            },
            AttributesToGet: [
                'Partner_ID',
                'Object_ID',
                'Contract_ID',
                'Object_ChassisNumber'
            ]
        }
        return new Promise( (resolve, reject ) => {
            this.getDocumentClient().then((documentClient) => {
              if (navigator.onLine) {
                  documentClient.query(params, (err, data) => {
                      if (err) reject(err)
                      else {
                          console.log(data);
                          resolve(data.Items);
                      }
                  });
              }
            });
        })
      }

    putObjectsByContractAux(partner_ID, object_ID, contract_ID, object_ChassisNumber): Promise<any> {
      let params = {
        TableName: environment.aws_table_tmp,
        Item: {
          Partner_ID: partner_ID,
          Object_ID: object_ID,
          Contract_ID: contract_ID,
          Object_ChassisNumber: object_ChassisNumber
        }
      }
      return new Promise( (resolve, reject ) => {
          this.getDocumentClient().then((documentClient) => {
            if (navigator.onLine) {
                documentClient.put(params, (err, data) => {
                    if (err) reject(err)
                    else {
                        console.log(data);
                        resolve(data);
                    }
                });
            }
          });
      })
    }

    getContracts(tableName, customerID = null, contractID = null, limit: Number = 12): Promise<any> {

        let params = {
            TableName: tableName,
            IndexName: environment.aws_lindeleasing_dynamodb_contracts_index,
            Select: "SPECIFIC_ATTRIBUTES",
            KeyConditions: {
                'Partner_ID': {
                    ComparisonOperator: 'EQ',
                    AttributeValueList: [this.auth.partnerID]
                }
            },
            QueryFilter: {
                'Contract_Disabled': {
                    ComparisonOperator: 'NE',
                    AttributeValueList: ['true']
                }
            },
            AttributesToGet: [
                'Contract_ID'
            ]
        }

        return new Promise((resolve, reject) => {
            this.getDocumentClient().then((documentClient) => {

                if (customerID) {
                    params.QueryFilter['Customer_ID'] = {
                        ComparisonOperator: 'EQ',
                        AttributeValueList: [customerID]
                    }
                }
                if (contractID) {
                    params.KeyConditions['Contract_ID'] = {
                        ComparisonOperator: 'BEGINS_WITH',
                        AttributeValueList: [contractID]
                    }
                }

                if (this.lastEvaluatedKey) {
                    params['ExclusiveStartKey'] = this.lastEvaluatedKey;
                }

                if (navigator.onLine) {
                    documentClient.query(params, (err, data) => {
                        if (err) reject(err)
                        else {
                            this.lastEvaluatedKey = data.LastEvaluatedKey
                            resolve(data.Items);
                        }
                    });
                }
            })
        });
    }

    getCustomers(table): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getDocumentClient().then((documentClient) => {
                let params = {
                    TableName: table,
                    IndexName: environment.aws_lindeleasing_dynamodb_customer_index,
                    KeyConditions: {
                        'Partner_ID': {
                            ComparisonOperator: 'EQ',
                            AttributeValueList: [this.auth.partnerID]
                        }
                    },
                    QueryFilter: {
                        'Contract_Disabled': {
                            ComparisonOperator: 'NE',
                            AttributeValueList: ['true']
                        }
                    },
                    Select: "SPECIFIC_ATTRIBUTES",
                    AttributesToGet: [
                        'Customer_Name1',
                        'Customer_Name2',
                        'Customer_Name3',
                        'Customer_ID'
                    ]
                }
                documentClient.query(params,
                    (err, data) => {
                        if (err) reject(err)
                        else {
                            resolve(data.Items);
                        }
                    }
                );
            })
        });
    }

    getJsonContractInfo(data): ContractInfo {
        let contractInfo = data.reduce((a, v) => {
            let contract: Contract = {
                ID: v.Contract_ID
            }
            let customer: Customer = {
                ID: v.Customer_ID,
                Name: v.Customer_Name1,
                Name2: v.Customer_Name2,
                Name3: v.Customer_Name3,
                City: v.Customer_City,
                Street: v.Customer_Street,
                ZipCode: v.Customer_ZipCode,
                OrderNumber: v.Object_OrderNumber,
                OrderDate: v.Object_OrderDate
            }
            let partner: Partner = {
                Name: v.Partner_Name1,
                Name2: v.Partner_Name2,
                Name3: v.Partner_Name3,
                Street: v.Partner_Street,
                City: v.Partner_City,
                ZipCode: v.Partner_ZipCode,
                ID: v.Partner_ID,
            }
            let object: Object = {
                ID: v.Object_ID,
                Name: v.Object_Name,
                CommissionNumber: v.Object_CommissionNumber,
                OrderDate: v.Object_OrderDate,
                ConstructionYear: v.Object_ConstructionYear,
                LocationStreet: v.Object_LocationStreet,
                LocationZipCode: v.Object_LocationZipCode,
                LocationCity: v.Object_LocationCity,
                LocationName1: v.Object_LocationName1,
                LocationName2: v.Object_LocationName2,
                LocationName3: v.Object_LocationName3,
                CostCenter: v.Object_CostCenter,
                OrderNumber: v.Object_OrderNumber,
                Type: v.Object_Type,
                Used: v.Object_Used,
                ChassisNumber: v.Object_ChassisNumber,
                Edit: "",
                Sparte: v.Object_Sparte,
                EditAux: false,
                ShowLoad: false,
                CopyChassisNumber: v.Object_ChassisNumber,
            }
            let takeover: Takeover = {
                Date: '',
                Signatory: '',
                SignatureDate: '',
                Latitude: '',
                Longitude: '',
                CustomerEmail: '',
                SendTimestamp: '',
                UserID: '',
                SignatoryOpcional: '',
                SignatureData: '',
                SignatureOpcionalData: '',
            }
            let confirmation: Confirmation = {
                PrivacyCheck: false,
                EmailReceipt: false,
                EmailNews: false
            }
            if (!a.Partner)
                a["Partner"] = partner;
            if (!a.Customer)
                a["Customer"] = customer;
            if (!a.Contract)
                a["Contract"] = contract;
            if (!a.Takeover)
                a["Takeover"] = takeover;
            if (!a.Confirmation)
                a["Confirmation"] = confirmation;
            if (!a.Objects)
                a['Objects'] = [];
            a['Objects'].push(object);
            return a
        }, {});
        return contractInfo;
    }

    buildKeys(eventData) {
        let objects = [];

        for (let obj of eventData.Objects) {
            objects.push(obj.ID);
        }

        return {
            Partner_ID: eventData.Partner.ID,
            Object_ID: objects
        };
    }

    disableContracts(table, contractInfo: ContractInfo): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getDocumentClient().then((documentClient) => {
                let keys = this.buildKeys(contractInfo);
                for (let key of keys.Object_ID) {
                    documentClient.get({
                        TableName: table,
                        Key: {
                            "Partner_ID": keys.Partner_ID,
                            "Object_ID": key
                        },
                        AttributesToGet: ['Contract_Disabled']
                    }, (err, data) => {
                        if (err) console.log(err);
                        else {
                            if (!data.Item.Contract_Disabled) {
                                let params = {
                                    TableName: table,
                                    Key: {
                                        "Partner_ID": keys.Partner_ID,
                                        "Object_ID": key
                                    },
                                    ExpressionAttributeNames: {
                                        "#S": "Contract_Disabled"
                                    },
                                    ExpressionAttributeValues: {
                                        ":d": "true"
                                    },
                                    UpdateExpression: "SET #S = :d"
                                };
                                documentClient.update(params, (err, data) => {
                                    if (err) console.log(err);
                                    else console.log(data);
                                });
                            }
                        }
                    });
                }
                resolve();
            });
        });
    }

    getObjectsByContract(table, contract_id): Promise<any> {
        let params = {
            TableName: table,
            IndexName: environment.aws_lindeleasing_dynamodb_contracts_index,
            KeyConditionExpression: 'Partner_ID = :Partner_ID and Contract_ID = :Contract_ID',
            ExpressionAttributeValues: {
                ':Partner_ID': this.auth.partnerID,
                ':Contract_ID': contract_id
            },
        }

        return new Promise((resolve, reject) => {
            this.getDocumentClient().then((documentClient) => {
                documentClient.query(params, (err, data) => {
                    if (err) reject(err);
                    else {
                        let contractData = this.getJsonContractInfo(data.Items);
                        resolve(contractData);
                    }
                });
            });
        });
    }

    getBatchWriteLimitData(dataObjects): Array<any> {
        let dataLimited = dataObjects.reduce((aData, elem) => {
            if(aData[aData.length - 1].length < 25) {
                aData[aData.length - 1].push(elem);
            } else {
                aData.push([elem]);
            }
            return aData;
        }, [[]]);
        return dataLimited;
    };
}

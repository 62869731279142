import { Injectable } from '@angular/core';
import { Config as AppConfig } from '@ionic/angular'
import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';

import { DynamoDB } from './aws.dynamodb'

// declare const aws_lindeleasing_dynamodb_partner;

@Injectable()
export class PartnerStorage extends DynamoDB {
  public tableName: String;

  constructor(protected config: AppConfig,
              protected auth: AuthService) {
    super(config, auth);
    this.tableName = environment.aws_lindeleasing_dynamodb_partner;
  }

  getPartnerName(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDocumentClient().then((documentClient) => {
        documentClient.query({
          TableName: this.tableName,
          KeyConditionExpression: 'Partner_ID = :Partner_ID',
          ExpressionAttributeValues: {':Partner_ID': id},
          Select: "SPECIFIC_ATTRIBUTES",
          ProjectionExpression: [
            'Partner_Name'
          ]
        }, (err, data) => {
          if (err) reject(err);
          else resolve(data.Items);
        });
      });
    });
  }
}
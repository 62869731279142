import { Injectable } from '@angular/core';
import { Config as AppConfig } from '@ionic/angular'
import { AuthService } from './auth.service';
var AWS = require('aws-sdk');

@Injectable()
export class DynamoDB {
  static documentClient: any;

  constructor(protected config: AppConfig,
              protected auth: AuthService) { }

  getDocumentClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (DynamoDB.documentClient === undefined) {
        this.auth.getCredentials().subscribe((newcredentials) => {
          DynamoDB.documentClient = new AWS.DynamoDB.DocumentClient({
            params: {
              credentials: newcredentials
            }
          });
          resolve(DynamoDB.documentClient);
        })
      } else {
        resolve(DynamoDB.documentClient);
      }
    });
  }

  static get parameters() {
    return [[AppConfig], [AuthService]];
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  aws_app_analytics: 'enable',
  aws_cognito_identity_pool_id: 'eu-central-1:ad0a34d1-929a-47db-bef6-a53280c9eafa',
  aws_cognito_region: 'eu-central-1',
  aws_content_delivery: 'enable',
  aws_content_delivery_bucket: 'serviceapptest-hosting-mobilehub-465591421',
  aws_content_delivery_bucket_region: 'eu-central-1',
  aws_content_delivery_cloudfront: 'enable',
  aws_content_delivery_cloudfront_domain: 'https://serviceapp-test.linde-leasing.de',
  aws_s3_buckets: '{"hosting":"serviceapptest-hosting-mobilehub-465591421","deployments":"serviceapptest-deployments-mobilehub-465591421"}',
  aws_dynamodb: 'enable',
  aws_dynamodb_all_tables_region: 'eu-central-1',
  aws_dynamodb_table_schemas: '[{"tableName":"serviceapptest-partner","attributes":[{"name":"Partner_ID","type":"S"},{"name":"Partner_Email","type":"S"},{"name":"Partner_Name","type":"S"}],"indexes":[],"region":"eu-central-1","hashKey":"Partner_ID"},{"tableName":"serviceapptest-contracts","attributes":[{"name":"Partner_ID","type":"S"},{"name":"Object_ID","type":"S"}],"indexes":[],"region":"eu-central-1","hashKey":"Partner_ID","rangeKey":"Object_ID"},{"tableName":"serviceapptest-archive","attributes":[{"name":"Partner_ID","type":"S"},{"name":"Object_ID","type":"S"}],"indexes":[],"region":"eu-central-1","hashKey":"Partner_ID","rangeKey":"Object_ID"}]',
  aws_mandatory_sign_in: 'enable',
  aws_mobile_analytics_app_id: '3e25a114c00c4246867227462c20018e',
  aws_mobile_analytics_region: 'us-east-1',
  aws_project_id: '',
  aws_project_name: 'linde-leasing-test',
  aws_project_region: 'eu-central-1',
  aws_resource_name_prefix: 'serviceapptest-mobilehub-465591421',
  aws_sign_in_enabled: 'enable',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'eu-central-1_xwlbOSKji',
  aws_user_pools_mfa_type: 'OFF',
  aws_user_pools_web_client_id: '3j23ch2pg80kshikmjuf1oh0gp',
  aws_lindeleasing_dynamodb_contracts: 'serviceapptest-contracts',
  aws_lindeleasing_dynamodb_archive: 'serviceapptest-archive',
  aws_lindeleasing_dynamodb_contracts_index: 'Partner_ID-Contract_ID-index',
  aws_lindeleasing_dynamodb_customer_index: 'Partner_ID-Customer_Name1-index',
  aws_lindeleasing_dynamodb_partner: 'serviceapptest-partner',
  aws_lindeleasing_lambda_confirmation_pdf: 'arn:aws:lambda:eu-central-1:889956211718:function:serviceapptest_generate-pdf-order-confirmation',
  aws_lindeleasing_lambda_signed_url: 'arn:aws:lambda:eu-central-1:889956211718:function:serviceapptest_signedurls',
  aws_lindeleasing_sns_lambda_topic: 'arn:aws:sns:eu-central-1:889956211718:serviceapptest',
  aws_table_tmp: 'serviceapptest-tmp'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error',  // Included with Angular CLI.
